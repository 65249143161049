import React, { useEffect, useState } from 'react'
import { CurrentValueDisplay } from './current-value-display'

// Import styles
import './../../styles/magic-beam.css'


interface MagicBeamUI {
    currentValue: number;
    currentColor: string;
    currentBarrierLocation: number;
    scoreRadius: number;
    currentBarrierRadius: number;
    beamWidth: number;

    translateWidth: number;
    translateHeight: number
}

// Create Puzzle component
export const MagicBeam = (props: MagicBeamUI) => {
    let laserClass = (props.currentColor) ? "laser-beam " + props.currentColor : "laser-beam";

    // Resizing
    const rotation = props.currentBarrierLocation;
    const radius = props.scoreRadius;
    const beamWidth = props.beamWidth;

    const translateWidthStart = props.translateWidth - radius; //Subtract off half the radius of the circle
    const translateHeightStart = props.translateHeight;
    const stylesStart = { 
        transform: `translate(${translateWidthStart}px, ${translateHeightStart}px)` 
    };

    const secondTranslationX = radius * Math.cos(rotation * Math.PI/180) * -1; // Calulation of rotation starts from 180, not 0
    const secondTranslationY = radius * Math.sin(rotation * Math.PI/180) * -1; //Calulation of rotation starts from 180, not 0
    const translateWidthBeam = props.translateWidth - beamWidth; //Subtract off the length of the beam
    const translateHeightBeam = props.translateHeight + radius- 2.5; // Add in the radius of the circle, but then subtract half the height of the beam
    const stylesBeam = { 
        transform: `translate(${secondTranslationX}px, ${secondTranslationY}px) translate(${translateWidthBeam}px, ${translateHeightBeam}px) rotate(${rotation}deg)`,
        transformOrigin: "center right",
        width: `${beamWidth}px`
    };
    return (
        <div className='magic-beam'>
            <div className='beam-start' style={stylesStart}></div>
            <div className={laserClass} style={stylesBeam}></div>
            <CurrentValueDisplay currentValue={props.currentValue} translateWidth={translateWidthStart} translateHeight={translateHeightStart} />
        </div>
    )
}
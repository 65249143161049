// Import deps
import React, { useState } from 'react'

// Create interfaces
interface PuzzleRowUI {
  position: number;
  user: number;
  puzzle: {
    title: string;
    score: number | undefined;
  }
  handlePickPuzzle: (puzzleTitle: string) => void;
}

// Create PuzzleRow component
export const PuzzleRow = (props: PuzzleRowUI) => {
  const [score, setScore] = useState<number | undefined>(props.puzzle.score)

  let formatScore = { };
  if(score == undefined) {
    // No further changes
  } else if(score == 0) {
    formatScore = {
      fontWeight: 'bold'
    }
  } else if(score && score < 10) {
    formatScore = {
      color: 'green'
    }
  } else {
    formatScore = {
      color: 'red'
    }
  }
  return (<tr className="table-row">
    <td className="table-item">
      {props.position}
    </td>
    <td className="table-item">
      <button
        className="btn btn-select"
        onClick={() => props.handlePickPuzzle(props.puzzle.title)}>
        {props.puzzle.title}
      </button>
    </td>

    <td className="table-item" style={formatScore}>
      {score}
    </td>
  </tr>
)
  }
// Import deps
import React, { useEffect, useState } from 'react'

// Import components
import { PuzzleList } from './puzzle-list'
import { StatisticsBar } from './statistics-bar'
import { useViewingWindow, isPhoneLandscape } from './../../shared/viewing-window'

// Import styles
import './../../styles/puzzle-table.css'


//interfaces
interface PuzzleData {
  title: string;
  score: number | undefined;
}

interface PuzzleTableUI {
  user: number;
  currentStreak: number;
  maxStreak: number;
  completed: number;
  puzzles: PuzzleData[];
  handlePickPuzzle: (puzzleTitle: string) => void;
  closePuzzleList: () => void;
}

// Create Puzzle component
export const PuzzleTable = (props: PuzzleTableUI) => {
  // Prepare states
  const [puzzles, setPuzzles] = useState(props.puzzles)

  const {width, height} = useViewingWindow();
    const translateY = ( isPhoneLandscape() )? height * .5 : height * .45;
    const styles = {
      transform: `translateY(${translateY}px)`
    }

  return (
    <>
    <div className="puzzle-table-wrapper" style={styles}>
      <PuzzleList 
        puzzles={puzzles} 
        user={props.user}
        handlePickPuzzle={props.handlePickPuzzle}
        />
      <StatisticsBar currentStreak={props.currentStreak} maxStreak={props.maxStreak} completed={props.completed} />
      <button
        className="btn standard-btn btn-close"
        onClick={() => props.closePuzzleList()}>
        Close Stats
      </button><br /><br />
    {!props.user && (
      "You do not have a user. Scores on the daily puzzle will not be saved. If you would like to save daily scores, please create a user."
    )
    }
    </div>
  </>
  )
}
// Import deps
import React, { useEffect, useState } from 'react'
import { OperationButton } from './operation-button'
import { UndoButton } from './undo-button'
import { ValueButton } from './value-button'
import { useViewingWindow, isPhoneLandscape } from './../../shared/viewing-window'

// Import styles
import './../../styles/interaction-panel-display.css'


//Interface
interface InteractionPanelUI {
    currentOptions: number[];
    disabledValues: number[];
    handleCurrentValue: (operator: string, value: number, identifier: number) => boolean;
    handleOperationsRemainingChange: (operationsRemainingChange: number) => void;
    handleSetDisabledValues: (array: number[]) => void;
    resetCurrentValue: () => void;
    handleRandomPuzzle: () => void;
    handleUndo: () => void;
}

// Create component
export const InteractionPanel = (props: InteractionPanelUI) => {
    const [currentOperation, setCurrentOperation] = useState('')
    const [currentSelectedValueIdentifier, setCurrentSelectedValueIdentifier] = useState<number>()

    const {width, height} = useViewingWindow();
    
    function calculateWidth(options: number[]) {
        const size = (options.length > 3)? options.length : 5;
        let width = size * (55) + 5;
        return width;
    }


    const changeValue = (value: number, identifier: number) => {
        (currentSelectedValueIdentifier == identifier)
            ? setCurrentSelectedValueIdentifier(undefined) 
            : setCurrentSelectedValueIdentifier(identifier);
    }
    useEffect(() => {
        handleCombination();
      }, [currentSelectedValueIdentifier]);

    const changeOperation = (operation: string, key: number) => {
        (currentOperation == operation)? setCurrentOperation('') : setCurrentOperation(operation);
    }
    useEffect(() => {
        handleCombination();
      }, [currentOperation]);


    function handleCombination() {
        if((currentOperation) && !(currentSelectedValueIdentifier === undefined)) {
            const valueChanged = props.handleCurrentValue(currentOperation, props.currentOptions[currentSelectedValueIdentifier], currentSelectedValueIdentifier);

            if(valueChanged) {
                let array = props.disabledValues;
                array.push(currentSelectedValueIdentifier);
                props.handleSetDisabledValues(array);
                
                props.handleOperationsRemainingChange(-1);
            }

            resetClicks();
        }
    }

    const handleRestart = () => {
        resetClicks();
        props.resetCurrentValue();
    }

    function resetClicks() {
        setCurrentOperation('');
        setCurrentSelectedValueIdentifier(undefined);
    }

    const panelWidth = calculateWidth(props.currentOptions);
    const interactionTranslateY = ( isPhoneLandscape() )? height * .01 : height * .5;
    const interactionTranslateX = ( isPhoneLandscape() )? width * .70 - panelWidth / 2 :  width * .5 - panelWidth / 2;
    const interactionWidth = panelWidth.toString() + 'px';
    const interactionPanelDisplay = {
        height: "125px", 
        width: interactionWidth,
        transform: `translate(${interactionTranslateX}px, ${interactionTranslateY}px)`
    }
    const buttonTranslateX = calculateWidth(props.currentOptions) / 2 - 100;
    const buttonDisplay = {
        transform: `translate(${buttonTranslateX}px, 20px)`
    }

    const handleUndo = () => {
        props.handleUndo();
    }
    
    return (
        <div className='interaction-panel-display' style={ interactionPanelDisplay }>
            <div className='interaction-options'>
                <table className='interaction-table'>
                    <tbody><tr>
                        {props.currentOptions.map((option: number, idx) => (
                        <ValueButton
                            key={"valueButton." + idx.toString()}
                            value={option}
                            currentSelectedValueIdentifier={currentSelectedValueIdentifier}
                            handleChange={changeValue}
                            identifier={idx}
                            disabledIdentifiers={props.disabledValues}
                        />
                        )
                        )}
                    </tr></tbody>
                </table>
            </div>
            <div className='interaction-operators'>
                <table className='interaction-table'>
                    <tbody><tr>
                        <td><OperationButton 
                            item={"+"}
                            currentValue={currentOperation}
                            identifier={1}
                            handleChange={changeOperation} 
                        /></td>
                        <td><OperationButton 
                            item={"-"}
                            currentValue={currentOperation}
                            identifier={2}
                            handleChange={changeOperation} 
                        /></td>
                        <td><OperationButton 
                            item={"x"}
                            currentValue={currentOperation}
                            identifier={3}
                            handleChange={changeOperation} 
                        /></td>
                        <td><OperationButton 
                            item={"/"}
                            currentValue={currentOperation}
                            identifier={4}
                            handleChange={changeOperation} 
                        /></td>
                        <td><UndoButton 
                            identifier={5}
                            handleUndo={handleUndo} 
                        /></td>
                    </tr></tbody>
                </table>
            </div>
            <div className = 'btn-panel' style={buttonDisplay}>
                <button onClick={() => handleRestart()} className='btn standard-btn'>Restart</button>
                <button onClick={props.handleRandomPuzzle} className='btn standard-btn'>Random</button>
            </div>
        </div>
    )
}
// Import deps
import React, { useEffect, useState } from 'react'
import { isPhoneLandscape } from '../../shared/viewing-window'
import './../../styles/navbar.css'

interface StepData {
    startingValue: number;
    endingValue: number;
    interaction: string;
    changeValue: number;
}

type puzzleStatusUI =  'win' | 'partial' | 'loss' | 'incomplete';

interface ResultsUI {
    width: number;
    height: number;
    steps: StepData[];
    bestScore: number | undefined;
    puzzleTitle: string;
    puzzleFinished: puzzleStatusUI;
    closeModal: () => void;
}
export const Results = (props: ResultsUI) => {
    const [showResults, setShowResults] = useState(true);

    const closeModal = () => {
        setShowResults(false);
        props.closeModal();
    }
    
      useEffect(() => {
        // Give the opened modal focus
        const modal = document.getElementById("results-modal");
        if(modal) {
            modal.focus();
        }
        document.addEventListener("keydown", (e: KeyboardEvent) => {if(e.key === "Escape") {closeModal()}}, false);
        return () => {
          document.removeEventListener("keydown", (e: KeyboardEvent) => {if(e.key === "Escape") {closeModal()}}, false);
        };
      }, []);

    const dialogWidth = props.width * .75;
    const dialogHeight = ( isPhoneLandscape() )? props.height * .95 : props.height * .8;
    const translate = ( isPhoneLandscape() )? props.height * .28 : props.height * .05;
    const fontSize = (isPhoneLandscape() || dialogHeight < 200 || dialogWidth < 400)? 10 : (dialogHeight < 600 || dialogWidth < 700)? 12 : 16;
    const modalStyles = {
        width: "100%",
        height: dialogHeight,
        maxWidth: "none",
        maxHeight: "none",
        fontSize: fontSize,
        transform: `translateY(-${translate}px)`
    }

    const stepToWords = (step: StepData) => {
        const operator = step.interaction;
        let words = '';
        if(operator == 'barrier') {
            words = `${step.startingValue} - ${step.changeValue} = ${step.endingValue} (barrier removed)`;
        } else {
            words = `${step.startingValue} ${operator} ${step.changeValue} = ${step.endingValue}`;
        }
        return words;
    }

    const stepToShare = (step: StepData) => {
        const operator = step.interaction;
        let word = '';
        if(operator == 'barrier') {
            word = `(-)`;
        } else {
            word = operator;
        }
        return word;
    }

    const copyShortSteps = () => {
        let out = '';
        props.steps.map((step: StepData, idx) => {
            if(out == '') {
                out = stepToShare(step)
            } else {
                out += " " + stepToShare(step)
            }
        });

        out = `MathLock ${props.puzzleTitle}: ${out}`;

        navigator.clipboard.writeText(out);
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if ((e.relatedTarget instanceof HTMLElement) && e.currentTarget.contains(e.relatedTarget)) {
            // Do not register if click is a child node
            return;
          }
          closeModal();
    }

    const getBestScore = () => {
        if(props.bestScore == undefined) {
            return "incomplete"
        }
        return props.bestScore;
    }

    const showShareButton = () => {
        //Share button only displays if the final score is 0
        if((props.bestScore==undefined) || !(props.bestScore == 0)) {
            return false;
        }

        //Share button only appears if the current steps result in 0
        const stepLength = props.steps.length;
        if(stepLength && !(props.steps[stepLength - 1]['endingValue'] == 0)) {
            return false;
        }

        //Share button only appears if user has currently "won"
        if(!(props.puzzleFinished == 'win')) {
            return false;
        }

        //share button only appears for daily puzzles
        if(props.puzzleTitle == 'Random Puzzle') {
            return false;
        }

        return true;
    }

    const showWin = () => {
        //Win only appears if the current steps result in 0
        const stepLength = props.steps.length;
        if(stepLength && !(props.steps[stepLength - 1]['endingValue'] == 0)) {
            return false;
        }

        //Display win if the result is a win
        if(props.puzzleFinished == 'win') {
            return true;
        }
        return false;
    }
    return (
        <div id = 'results-modal' className = 'results-modal' onBlur={handleBlur} tabIndex={-1}>
            <dialog className="dialog" style={modalStyles} open={showResults}>
                <button onClick={() => closeModal()} className="dialog-close-btn">X</button>
                <div className='results-content'> <h1>Results</h1>
                {showWin() && <span style={{fontWeight: 'bold'}}>You Win!</span>}
                </div>
                <div className='results-display'>
                    <span> Best Score: {getBestScore()} </span>
                    {showShareButton() && 
                        <span><br /><button className='share-content standard-btn' onClick = {() => copyShortSteps()}> share </button></span>
                    }
                </div><br /> <br />
                {props.steps.length > 0 && <div className='steps-content'> <h1>Steps Taken</h1>
                    <span className='steps-display'>
                        { props.steps.map((step: StepData, idx) => (
                            <div className='step' key = {idx}>{stepToWords(step)}</div>
                        ))}
                    </span>
                </div>}
            </dialog>
        </div>
    )
}
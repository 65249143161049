import React, { useEffect, useState, createContext, useContext, ReactNode  } from 'react'

interface globalContextUI {
  width: number;
  height: number;
}

interface globalContextChildren {
  children: ReactNode;
}

const viewingWindowContext = createContext<globalContextUI>({width: window.innerWidth, height: window.innerHeight});

export const ViewingWindowProvider = ( {children}: globalContextChildren ) => {
  const calculateHeight = () => {
    const maxHeight = 1000;
    return (window.innerHeight > maxHeight)? maxHeight : window.innerHeight;
  }


  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(calculateHeight());

  const handleWindowResize = () => {
    //Set Max width and height:
    setWidth(window.innerWidth);
    setHeight(calculateHeight());
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  useEffect(() => {
    window.addEventListener("orientationchange", handleWindowResize);
    return () => window.removeEventListener("orientationchange", handleWindowResize);
  }, []);

  return (
    <viewingWindowContext.Provider value={{ width, height }}>
      {children}
    </viewingWindowContext.Provider>
  );
};

export const useViewingWindow = () => {
  /* We can use the "useContext" Hook to acccess a context from within
     another Hook, remember, Hooks are composable! */
  const { width, height } = useContext(viewingWindowContext);
  return { width, height };
}

export const getScreenClass = () => {
  // Logic for adding classes to resize elements based on assumed viewing window
  const { width, height } = useContext(viewingWindowContext);
  return getScreenClassForDimensions(width, height);
}

export const getViewType = () => {
  const { width, height } = useContext(viewingWindowContext);
  return getViewTypeForDimensions(width, height);
}

export const isMobile = () => {
  const screen = getScreenClass();
  return (screen == 'mobile')? true : false;
}

export const isPhoneLandscape = () => {
  const view = getViewType();
  return (view == 'landscape' && isMobile())? true : false;
}

export const isScreenExtraSmall = () => {
  const { width, height } = useContext(viewingWindowContext);
  const viewType = getViewType();
  if( viewType == 'portrait' && width < 400 ) {
    return true;
  } else if( viewType == 'landscape' && height < 400 ) {
    return true;
  }
  
  return false;
}

// Methods for calculation based on input values, rather than react values in memory
export const getScreenClassForDimensions = (width: number, height: number) => {
  // Logic for adding classes to resize elements based on assumed viewing window
  const viewType = getViewTypeForDimensions(width, height);
  let screen = 'desktop';
  if( width < 700 && viewType == "portrait" ) {
    screen = 'mobile';
  }
  if( height < 700 && viewType == "landscape" ) {
    screen = 'mobile';
  }
  
  return screen;
}

export const getViewTypeForDimensions = (width: number, height: number) => {
  let screen = 'portrait';
  if( width > height ) {
    screen = 'landscape';
  }
  
  return screen;
}

export const isMobileForDimensions = (width: number, height: number) => {
  const screen = getScreenClassForDimensions(width, height);
  return (screen == 'mobile')? true : false;
}

export const isPhoneLandscapeForDimensions = (width: number, height: number) => {
  const view = getViewTypeForDimensions(width, height);
  return (view == 'landscape' && isMobileForDimensions(width, height))? true : false;
}

import React, { useEffect, useState } from 'react'

export const DivisionButton = () => {
    return (
        <span>
        <svg
            className='division-symbol'
            width="25" 
            height="25" 
            viewBox="0 0 25 25"
            fill="currentColor"
        >
                <line strokeLinecap="round" stroke="white" strokeWidth="4" y2="12.5" x2="22.5" y1="12.5" x1="2.5"></line>
                <circle r="3" cx="12.5" cy="6" fill="white" />
                <circle r="3" cx="12.5" cy="19" fill="white" />
        </svg>
        </span>
    )
}
import React, { useEffect, useState } from 'react'

// Import styles
import './../../styles/interactable-button.css'


interface CurrentValueUI {
    identifier: number;
    handleUndo: () => void;
}

// Create Puzzle component
export const UndoButton = (props: CurrentValueUI) => {
    return (
        <div className='interactable-button-display'>
            <button className='undo-button' onClick={() => props.handleUndo()}>
                <svg
                    className="undo-symbol"
                    width="24" 
                    height="24" 
                    viewBox="0 0 24 24"
                    fill="currentColor"
                >
                    <path d="M18.885 3.515c-4.617-4.618-12.056-4.676-16.756-.195l-2.129-2.258v7.938h7.484l-2.066-2.191c2.82-2.706 7.297-2.676 10.073.1 4.341 4.341 1.737 12.291-5.491 12.291v4.8c3.708 0 6.614-1.244 8.885-3.515 4.686-4.686 4.686-12.284 0-16.97z" />
                </svg>
            </button>
        </div>
    )
}
// Import deps
import React from 'react'
import { render } from 'react-dom'

// Import components
import { Puzzles } from './components/puzzles'

// Import styles
import './styles/styles.css'

// Find div container
const rootElement = document.getElementById('root')

// Render puzzle component in the DOM
render(<Puzzles />, rootElement)
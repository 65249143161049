import React, { useEffect, useState } from 'react'

// Import styles
import './../../styles/current-value-display.css'


interface CurrentValueUI {
    currentValue: number;
    translateWidth: number;
    translateHeight: number;
}

// Create Puzzle component
export const CurrentValueDisplay = (props: CurrentValueUI) => {
    // Font sizes that fit in the current value circle
    // 25 -> 21 -> 17 -> 13 -> 9
    let fontSize = 25;
    if( props.currentValue > 0 ) {
        //Positive values
        if( props.currentValue < 1000 ) {
            // No change
        } else if  (props.currentValue < 10000 ) {
            fontSize = 21;
        } else if  (props.currentValue < 100000 ) {
            fontSize = 17;
        } else if  (props.currentValue < 1000000 ) {
            fontSize = 13;
        } else {
            fontSize = 9;
        }
    } else {
        //Negative values
        if( props.currentValue > -100 ) {
            // No change
        } else if  (props.currentValue > -1000 ) {
            fontSize = 21;
        } else if  (props.currentValue > -10000 ) {
            fontSize = 17;
        } else if  (props.currentValue > -100000 ) {
            fontSize = 13;
        } else {
            fontSize = 9;
        }
    }
    const styles = { 
        transform: `translate(${props.translateWidth}px, ${props.translateHeight}px)`,
        fontSize: fontSize
    };
    return (
        <div className='current-value-display'>
            <div className='circle' style={styles}>{props.currentValue}</div>
        </div>
    )
}
// Import deps
import React, { useEffect, useState } from 'react'

// Import components
import { PuzzleTable } from './puzzle-list/puzzle-table'
import { useViewingWindow, getScreenClass, isScreenExtraSmall } from './../shared/viewing-window'

// Import styles
import './../styles/main-screen.css'


//interfaces
interface PuzzleScoreData {
  title: string;
  score: number | undefined;
}

interface MainScreenUI {
  user: number;
  currentStreak: number;
  maxStreak: number;
  completed: number;
  puzzles: PuzzleScoreData[];
  handlePickPuzzle: (puzzleTitle: string) => void;
  handleRandomPuzzle: () => void;
  handleDaily: () => void;
}

export const MainScreen = (props: MainScreenUI) => {
    const [showPuzzleList, setShowPuzzleList] = useState(false)

    const closePuzzleList = () => {
      setShowPuzzleList(false);
    }

    const {width, height} = useViewingWindow();
    let screenClass = getScreenClass();

    const translateForMobile = (screenClass == 'mobile')?  height / 6 : 0;

    const translateY = height / 2 - translateForMobile;
    const styles = {
      transform: `translateY(${translateY}px)`
    }
    const translateYTitle = translateY - 100;
    const stylesTitle = {
      transform: `translateY(${translateYTitle}px)`,
      ...(isScreenExtraSmall() && { fontSize : '40px' })
    }

    return (
        <div className={screenClass}>
          <div className="main-screen">
              {(showPuzzleList)? (
                  <PuzzleTable 
                      user={props.user}
                      currentStreak={props.currentStreak}
                      maxStreak={props.maxStreak}
                      completed={props.completed}
                      puzzles={props.puzzles}
                      handlePickPuzzle={props.handlePickPuzzle} 
                      closePuzzleList={closePuzzleList}
                  />
            ) :
            (<>
            <div className='main-title title-font' style={stylesTitle}>
              <span className='fire'>Math</span><span className='water'>Lock</span>
            </div>
              <div className='options' style={styles}>
              <button
                  className="btn standard-btn btn-daily"
                  onClick={() => props.handleRandomPuzzle()}>
                  Random puzzle
              </button>
              <button
                  className="btn standard-btn btn-daily"
                  onClick={() => props.handleDaily()}>
                  Daily puzzle
              </button> 
              <button
                  className="btn standard-btn btn-stats"
                  onClick={() => setShowPuzzleList(true)}>
                  Show Stats
              </button> 
            </div>
            </>)
          }
          </div>
      </div>
      )
    }
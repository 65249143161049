// Import deps
import React from 'react'
// Import styles
import './../../styles/statistics-bar.css'

// Create interfaces
interface StatisticsUI {
  currentStreak: number;
  maxStreak: number;
  completed: number;
}

// Create PuzzleList component
export const StatisticsBar = (props: StatisticsUI) => {

  return (
    <div className='statistics-bar'>
      <table className="statistics-table">
        <thead>
          <tr>
            <th>Dailies<br />Completed</th>
            <th>Current<br />Streak</th>
            <th>Max<br />Streak</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{props.completed}</td>
            <td>{props.currentStreak}</td>
            <td>{props.maxStreak}</td>
          </tr>
        </tbody>
    </table>
    </div>
  )
}
import React, { useEffect, useState } from 'react'
import {TimesButton} from './operation-buttons/times-button'
import {AdditionButton} from './operation-buttons/addition-button'
import {SubtractionButton} from './operation-buttons/subtraction-button'
import {DivisionButton} from './operation-buttons/division-button'

// Import styles
import './../../styles/interactable-button.css'


interface CurrentValueUI {
    item: string;
    currentValue: string;
    identifier: number;
    handleChange: (changedValue: string, key: number) => void;
}

// Create Puzzle component
export const OperationButton = (props: CurrentValueUI) => {
    return (
        <div className='interactable-button-display'>
            <button className={(props.item == props.currentValue)? 'circle-clicked' : 'circle'} onClick={() => props.handleChange(props.item, props.identifier)}>
                {(props.item == 'x') ? 
                    (<TimesButton />)
                : (props.item == '+') ? 
                    (<AdditionButton />)
                : (props.item == '-') ? 
                    (<SubtractionButton />)
                : (props.item == '/') ? 
                    (<DivisionButton />)
                :
                    props.item
                }
            </button>
        </div>
    )
}
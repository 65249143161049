import React, { useEffect, useState } from 'react'
import { BarrierPart } from './magic-barrier-part'

// Import styles
import './../../styles/magic-barrier.css'

interface LockUI {
    fire: number;
    electric: number;
    water: number;
}

interface BarrierUI {
    locks: LockUI[];
    lockRadius: number;
    scoreRadius: number;
    translateWidth: number;
    translateHeight: number;
    handleAttack: (onClickEvent: any) => void;
}

// Create Puzzle component
export const Barrier = (props: BarrierUI) => {

    function getType(lock: LockUI) {
        let types = [];
        
        if(lock.fire > 0) {
            types.push('fire');
        }
        if(lock.electric > 0) {
            types.push('electric');
        }
        if(lock.water > 0) {
            types.push('water');
        }

        return types.join('-');
    }

    function getStrength(lock: LockUI) {
        let strength = 0;
        strength = strength + lock.fire + lock.electric + lock.water;

        return strength;
    }

    return (
        <div className='magic-barrier'>
            { props.locks.map((lock: LockUI, idx) => (
                <BarrierPart
                    key={idx}
                    type={getType(lock)}
                    lockRadius={props.lockRadius}
                    strength={getStrength(lock)}
                    currentLock={idx+1}
                    totalLocks={props.locks.length}
                    scoreRadius={props.scoreRadius}
                    translateWidth={props.translateWidth}
                    translateHeight={props.translateHeight}
                    handleAttack={props.handleAttack}
                />
            ))}
        </div>
    )
}
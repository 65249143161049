// Import deps
import React, { useEffect, useState } from 'react'

//Interface
interface DisplayUserUI {
    uniqueID: string;
}

// Create Puzzle component
export const DisplayUser = (props: DisplayUserUI) => {
  return (
    <>
      Your user identifier is: 
      <div className='user-id'>{props.uniqueID}</div> <br /> <br />
      If you would like to save your stats across different devices, please save this identifier.
    </>
  )
}
import React, { useEffect, useState } from 'react'

// Import styles
import './../../styles/interactable-button.css'


interface CurrentValueUI {
    value: number;
    currentSelectedValueIdentifier: number | undefined;
    identifier: number;
    disabledIdentifiers: number[]
    handleChange: (changedValue: number, key: number) => void;
}

// Create Puzzle component
export const ValueButton = (props: CurrentValueUI) => {
    function checkDisabled() {
        return props.disabledIdentifiers.includes(props.identifier);
    }
    function getClasses() {
        let circleClass = (!(props.currentSelectedValueIdentifier === undefined) && props.identifier == props.currentSelectedValueIdentifier)? 'circle-clicked' : 'circle';
        let comboClass = (checkDisabled())? circleClass + "-disabled" : circleClass;

        return comboClass;
    }

    function handleClick(value: number, identifier: number) {
        if(!checkDisabled()) {
            // Can't click the button if its been disabled
            props.handleChange(value, identifier);
        }
    }
    return (
        <td>
            <div className='interactable-button-display number'>
                <button className={getClasses()} onClick={() => handleClick(props.value, props.identifier)}>{props.value}</button>
            </div>
        </td>
    )
}
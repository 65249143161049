// Import deps
import React, { useEffect, useState } from 'react'
import { CreateUser } from './create-user'
import { DisplayUser } from './display-user'
import { isPhoneLandscape } from './../../shared/viewing-window'
import './../../styles/user-modal.css'

//Interfaces
interface UserModalUI {
    width: number;
    height: number;
    userUniqueIdentifier: string;
    setCurrentUserData: (id: number, unique_key: string) => void;
    closeModal: () => void;
}
export const UserModal = (props: UserModalUI) => {
    const [showUserModal, setShowUserModal] = useState(true);

    const closeModal = () => {
        setShowUserModal(false);
        props.closeModal();
    }
    
      useEffect(() => {
        // Give the opened modal focus
        const modal = document.getElementById("user-modal");
        if(modal) {
            modal.focus();
        }
        document.addEventListener("keydown", (e: KeyboardEvent) => {if(e.key === "Escape") {closeModal()}}, false);
        return () => {
          document.removeEventListener("keydown", (e: KeyboardEvent) => {if(e.key === "Escape") {closeModal()}}, false);
        };
      }, []);

    const dialogWidth = props.width * .75;
    const dialogHeight = ( isPhoneLandscape() )? props.height * .95 : props.height * .8;
    const translate = ( isPhoneLandscape() )? props.height * .23 : props.height * .05;
    const fontSize = (isPhoneLandscape() || dialogHeight < 200 || dialogWidth < 400)? 10 : (dialogHeight < 600 || dialogWidth < 700)? 12 : 16;
    const modalStyles = {
        width: "100%",
        height: dialogHeight,
        maxWidth: "none",
        maxHeight: "none",
        fontSize: fontSize,
        transform: `translateY(-${translate}px)`
    }
    
    const isUserDefined = () => {
        if((props.userUniqueIdentifier === undefined) || (props.userUniqueIdentifier == '')) {
            return false;
        }
        return true;
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if ((e.relatedTarget instanceof HTMLElement) && e.currentTarget.contains(e.relatedTarget)) {
            // Do not register if click is a child node
            return;
          }
          closeModal();
    }
    return (
        <div id = 'user-modal' className = 'user-modal' onBlur={handleBlur} tabIndex={-1}>
            <dialog className="dialog" style={modalStyles} open={showUserModal}>
                <button onClick={() => closeModal()} className="dialog-close-btn">X</button>
                <div className='user-content'>
                {(isUserDefined()) ? 
                <DisplayUser uniqueID={props.userUniqueIdentifier} />
                : <CreateUser setCurrentUserData={props.setCurrentUserData} />
                }
                </div>
            </dialog>
        </div>
    )
}
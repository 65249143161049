// Import deps
import React, { useEffect, useState } from 'react'
import axios from 'axios'

// Import components
import { backendHost, axiosConfig } from '../../shared/globalVar'


//Interface
interface CreateUserUI {
    setCurrentUserData: (id: number, unique_key: string) => void;
}

// Create Puzzle component
export const CreateUser = (props: CreateUserUI) => {
  const [getUserFailed, setGetUserFailed] = useState(false)

  const createUser = async () => {
    setGetUserFailed(false);
    let getRequestParams = {
      headers: axiosConfig.headers
    }
    axios
      .post(backendHost + '/users/create', {}, getRequestParams)
      .then(response => {
        // Create user
        props.setCurrentUserData(response.data.id, response.data.unique_key);
      })
      .catch(error => console.error(`There was an error retrieving the user: ${error}`))
  }

  const searchForUser = (event: React.FormEvent<HTMLFormElement>) => { 
    event.preventDefault(); 
    const form = event.currentTarget; 
    const input = form.elements.namedItem('unique_key') as HTMLInputElement; 
    getUser(input.value);
  } 

  const getUser = async (user: string) => {
    setGetUserFailed(false);
    let getRequestParams = {
      params: {unique_key: user},
      headers: axiosConfig.headers
    }
    axios
      .get(backendHost + '/users/get', getRequestParams)
      .then(response => {
        if( response.data.id ) {
          props.setCurrentUserData(response.data.id, response.data.unique_key);
        } else {
          setGetUserFailed(true);
        }
      })
      .catch(error => console.error(`There was an error retrieving the user: ${error}`))
  }

  return (
    <div className='create-user'>
      You do not have a saved user on this device. If you have a unique identifier provided from a previous device, enter it here.
      <div className='link-user-form'>
        <form onSubmit={searchForUser}>
          <div className='link-user-box'>
            <input name="unique_key" />
          </div>
          <div><button type="submit" className="btn standard-btn btn-link-user">Link User</button></div>
        </form>
        {
          getUserFailed && (<div className='link-user-error'>User not found.</div>)
        }
    </div>
      <br /><br />
      If you do not already have a user, your daily scores will not be saved. If you would like to track daily scores, please create a user. 
      No data will be tracked other than your scores for the last seven days and the length of your streaks. 
      A unique identifier will be provided to you if you would wish to sync your daily scores across different devices.
      <div>
        <button
            className="btn standard-btn btn-create-user"
            onClick={() => createUser()}>
            Create User
        </button> 
      </div>
    </div>
  )
}
import React, { useEffect, useState } from 'react'

export const AdditionButton = () => {
    return (
        <span><svg
            className='addition-symbol'
            width="25" 
            height="25" 
            viewBox="0 0 25 25"
            fill="currentColor"
        >
            <line strokeLinecap="round" stroke="white" strokeWidth="4" y2="12.5" x2="22.5" y1="12.5" x1="2.5"></line>
            <line strokeLinecap="round" stroke="white" strokeWidth="4" x1="12.5" y1="22.5" x2="12.5" y2="2.5"></line>
        </svg>
        </span>
    )
}
// Import deps
import React, { useEffect, useState } from 'react'
import axios from 'axios'

// Import components
import { NavBar } from './navbar/navbar'
import { MainScreen } from './main-screen'
import { Puzzle } from './puzzle/puzzle'
import { backendHost, axiosConfig } from './../shared/globalVar'
import { ViewingWindowProvider } from './../shared/viewing-window'
import { getRandomPuzzle } from './../logic/random-puzzle-controller'

const emptyPuzzle = {
  id: 0,
  title: '',
  locks: [{
    fire: 0,
    electric: 0,
    water: 0
  }],
  starting: 0,
  options: [],
};

//Interface
interface LockUI {
  fire: number;
  electric: number;
  water: number;
}

interface PuzzleDataUI {
  id: number;
  title: string;
  locks: LockUI[];
  starting: number;
  options: number[];
}

/*
  Interactions:
    Barrier
    Plus
    Minus
    Multiply
    Divide
*/
interface StepData {
  startingValue: number;
  endingValue: number;
  interaction: string;
  changeValue: number;
  identifier: number
}

interface StreakData {
  maxStreak: number;
  currentStreak: number;
  lastCompletedPuzzle: string;
  completed: number;
}

interface ScoreData {
  [puzzleTitle: string]: number | undefined;
}

type puzzleStatusUI =  'win' | 'partial' | 'loss' | 'incomplete';

// Create Puzzle component
export const Puzzles = () => {
  const [puzzle, setPuzzle] = useState<PuzzleDataUI>(emptyPuzzle)
  const [puzzleKey, setPuzzleKey] = useState(1)
  const [currentDisplay, setCurrentDisplay] = useState('puzzle-table')
  const [currentUserID, setCurrentUserID] = useState(0)
  const [currentUser, setCurrentUser] = useState('')
  const [steps, setSteps] = useState<StepData[]>([])
  const [streakObject, setStreakObject] = useState<StreakData>({maxStreak: 0, currentStreak: 0, lastCompletedPuzzle: '', completed: 0})
  const [scoreObject, setScoreObject] = useState<ScoreData>({})
  const [currentBestScore, setCurrentBestScore] = useState<number|undefined>(undefined);
  const [puzzleFinished, setPuzzleFinished] = useState<puzzleStatusUI>('incomplete');

  // If Current Value changes, check end conditions
  useEffect(() => {

    // Switch for what top-level view the app is currently serving
    if(puzzle.id) {
      setCurrentDisplay('puzzle-view')
    } else {
      setCurrentDisplay('puzzle-table')
    }
  }, [puzzle]);
  useEffect(() => {
    // When user is loaded, also load its stats
    if(currentUserID > 0) {
      getUserStats();
    }
  }, [currentUserID]);

  const loadPuzzle = (puzzleLoaded: PuzzleDataUI) => {
    //Clear out any steps
    setSteps([]);

    //Load the puzzle data into props
    setPuzzle(puzzleLoaded);
  }

  const getStreak = async () => {
    let getRequestParams = {
      params: {user: currentUserID},
      headers: axiosConfig.headers
    }
    axios
      .get(backendHost + '/users/getStreak', getRequestParams)
      .then(response => {
        // Get streak data
        if(response.data && !(response.data.user === undefined)) {
          setStreakObject({
            maxStreak: response.data.max_streak,
            currentStreak: response.data.current_streak,
            lastCompletedPuzzle: response.data.last_completed_puzzle,
            completed: response.data.completed
          })
        }
      })
      .catch(error => console.error(`There was an error retrieving the user: ${error}`))
  }

  const getWeekly = async () => {
    let getRequestParams = {
      params: {user: currentUserID},
      headers: axiosConfig.headers
    }
    axios
      .get(backendHost + '/users/getWeeklyStats', getRequestParams)
      .then(response => {
        // Get Weekly data
        if(response.data) {
          setScoreObject(response.data);
        }
      })
      .catch(error => console.error(`There was an error retrieving the user: ${error}`))
  }

  const getUserStats = () => {
    if(currentUserID == 0) {
      // No user, don't get stats
      return;
    }
    getStreak();
    getWeekly();
  }

  const getStatsForCurrentPuzzle = async (puzzleReturn: PuzzleDataUI) => {
    if(currentUserID == 0) {
      // No user do not get stats
      loadPuzzle(puzzleReturn);
      return;
    }
    if(puzzleReturn.id <= 0) {
      // Not an official puzzle, do not get stats
      loadPuzzle(puzzleReturn);
      return;
    }
    const loadedBestScore = (!(scoreObject[puzzleReturn.title] === undefined) && !(scoreObject[puzzleReturn.title] === null))? scoreObject[puzzleReturn.title] : undefined;
    setCurrentBestScore(loadedBestScore);
    loadPuzzle(puzzleReturn);
  }
  
  const fetchRandomPuzzle = async () => {
    // Send GET request to 'puzzles/random' endpoint
    getRandomPuzzle()
      .then(puzzleData => {
        // Update the puzzle state
        loadPuzzle(puzzleData)

        // Reset the score
        setCurrentBestScore(undefined);
        setPuzzleFinished('incomplete')

        //Rerender the component
        setPuzzleKey(puzzleKey + 1)
      })
      .catch(error => console.error(`There was an error retrieving the puzzle list: ${error}`))
  }

  const fetchPuzzle = async (title: string) => {
    // Send GET request to 'puzzles/get' endpoint
    let getRequestParams = {
      params: {title: title},
      headers: axiosConfig.headers
    }
    axios
      .get(backendHost + '/puzzles/getByTitle', getRequestParams)
      .then(response => {
        // Update the puzzle state
        if(!(response.data['starting'] == undefined)) {
          //Get user stats and set the puzzle data
          getStatsForCurrentPuzzle(response.data);
        } else {
          // Puzzle not loaded
        }
      })
      .catch(error => console.error(`There was an error retrieving the puzzle list: ${error}`))
  }

  const getOrCreateDaily = async () => {
    // Send GET request to 'puzzles/all' endpoint
    let requestParams = {
      headers: axiosConfig.headers
    }
    axios
      .put(backendHost + '/puzzles/daily', requestParams)
      .then(response => {
        // Update the puzzle state
        if(!(response.data['starting'] == undefined)) {
          //The get request returned a full puzzle if starting data is included
          //Set user stats
          getStatsForCurrentPuzzle(response.data);
        }
      })
      .catch(error => console.error(`There was an error retrieving the puzzle list: ${error}`))
  }

  const handleShowMainMenu = () => {
    loadPuzzle(emptyPuzzle)
  }
 
  const handlePickPuzzle = (puzzleTitle: string) => {
    fetchPuzzle(puzzleTitle)
  }
  
  const handleRandomPuzzle = () => {
    fetchRandomPuzzle();
  }

  const getUser = async (user: string) => {
    if( currentUserID > 0) {
      // Do not ask for a user if we have a user ID already
      return;
    }
    let getRequestParams = {
      params: {unique_key: user},
      headers: axiosConfig.headers
    }
    axios
      .get(backendHost + '/users/get', getRequestParams)
      .then(response => {
        // save user in memory
        setCurrentUserData(response.data.id, response.data.unique_key)
      })
      .catch(error => console.error(`There was an error retrieving the user: ${error}`))
  }

  const saveLocalScore = (score: number) => {
    if((currentBestScore == undefined) || (currentBestScore > score)) {
      setCurrentBestScore(score);
    }
  }

  const savePuzzleStats = async (puzzleId: number, score: number, puzzleTitle: string) => {
    //Update local data for render
    saveLocalScore(score);
    if(puzzleId <= 0) {
      //Random puzzle shouldn't have saved stats
      return;
    }
    if(currentUserID == 0) {
      //If the user isn't logged in, don't bother sending it to the database
      return;
    }
    const bestScore = scoreObject[puzzle.title];
    if(!(bestScore === undefined) && !(bestScore === null) && bestScore <= score) {
      // If the user's best score on the puzzle is defined and less than the current score. 
      // Do not send a request to the backend to save it
      return;
    }
    let requestParams = {
      headers: axiosConfig.headers
    }

    axios
      .put(backendHost + '/users/saveStatsForPuzzle', {user: currentUserID, puzzle: puzzleTitle, score: score}, requestParams)
      .then(response => {
        // Set the score object to reflect the new score
        scoreObject[puzzle.title] = score;
      })
      .catch(error => console.error(`There was an error saving the puzzle stats: ${error}`))

    //Only save the streak if the score is 0
    if( score == 0 ) {
      axios
      .put(backendHost + '/users/saveStreak', {user: currentUserID, puzzle: puzzleTitle}, requestParams)
      .then(response => {
        // Everything is good
        if( !(response.data.data.last_completed_puzzle === undefined)) {
          setStreakObject({
            maxStreak: response.data.data.max_streak,
            currentStreak: response.data.data.current_streak,
            lastCompletedPuzzle: response.data.data.last_completed_puzzle,
            completed: response.data.data.completed
          })
        }
      })
      .catch(error => console.error(`There was an error saving the puzzle stats: ${error}`))
    }
  }

  const saveUserInLocalStorage = (uniqueKey: string) => {
    localStorage.setItem("mage_lock_unique_key", uniqueKey);
  }

  const getUserInLocalStorage = () => {
    return localStorage.getItem("mage_lock_unique_key");
  }

  const loadUserFromLocalStorage = () => {
    const localUser = getUserInLocalStorage();
    if( !(localUser == undefined) && localUser != '' ) {
      getUser(localUser)
    }
  }

  const setCurrentUserData = (id: number, unique_key: string) => {
    setCurrentUserID(id);
    setCurrentUser(unique_key);
    saveUserInLocalStorage(unique_key);
  }


  const handleAddStep = (step: StepData) => {
    let currentSteps = steps;
    currentSteps.push(step);
    setSteps(currentSteps);
  }

  const handleRemoveStep = () => {
    let currentSteps = [...steps];
    const newSteps = currentSteps.slice(0,-1);
    setSteps([...newSteps]);
  }

  const handleResetSteps = () => {
    setSteps([]);
  }

  const handlePuzzleFinished = (result: puzzleStatusUI) => {
    setPuzzleFinished(result)
  }

  const getPuzzleData = () => {
    let puzzleData = [];
    // Sort the dates into decending order
    const sortedDates = Object.keys(scoreObject).sort((a,b) => {
      const [monthA, dayA, yearA] = a.split('-');
      const [monthB, dayB, yearB] = b.split('-');

      const A = parseInt(`${yearA}${monthA}${dayA}`);
      const B = parseInt(`${yearB}${monthB}${dayB}`);
      return B - A;
    });
    for (let i = 0; i < sortedDates.length; i++) {
      const key = sortedDates[i];
      const value = scoreObject[key];
      puzzleData.push({
        title: key,
        score: (typeof value === 'number')? value : undefined
      });
    }
    return puzzleData;
  }

  loadUserFromLocalStorage();

  return (
    <div className='entire-screen'>
    <ViewingWindowProvider>
      {puzzle.id
      ? (<Puzzle 
          puzzle={puzzle} 
          savePuzzleStats={savePuzzleStats}
          handleRandomPuzzle={handleRandomPuzzle}
          steps={steps}
          handleAddStep={handleAddStep}
          handleRemoveStep={handleRemoveStep}
          handleResetSteps={handleResetSteps}
          handlePuzzleFinished={handlePuzzleFinished}
          key={puzzleKey}
          bestScore={currentBestScore} />)
      : (<MainScreen 
          user={currentUserID}
          currentStreak={streakObject.currentStreak}
          maxStreak={streakObject.maxStreak}
          completed={streakObject.completed}
          puzzles={getPuzzleData()}
          handlePickPuzzle={handlePickPuzzle} 
          handleRandomPuzzle={fetchRandomPuzzle} 
          handleDaily={getOrCreateDaily}
          />)
      }
      <NavBar 
        currentDisplay={currentDisplay}
        setCurrentUserData={setCurrentUserData}
        userUniqueIdentifier={currentUser}
        handleShowMainMenu={handleShowMainMenu}
        steps={steps}
        bestScore={currentBestScore}
        puzzleTitle={puzzle.title}
        puzzleFinished={puzzleFinished}
        />
    </ViewingWindowProvider>
    </div>
  )
}
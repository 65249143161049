// Import deps
import React, { useEffect, useState } from 'react'
import { isPhoneLandscape } from './../../shared/viewing-window'
import './../../styles/navbar.css'

// Create Puzzle component
//Interfaces
interface HelpUI {
    width: number;
    height: number;
    closeModal: () => void;
}
export const Help = (props: HelpUI) => {
    const [showHelp, setShowHelp] = useState(true);

    const closeModal = () => {
        setShowHelp(false);
        props.closeModal();
    }
    
      useEffect(() => {
        // Give the opened modal focus
        const modal = document.getElementById("help-modal");
        if(modal) {
            modal.focus();
        }
        document.addEventListener("keydown", (e: KeyboardEvent) => {if(e.key === "Escape") {closeModal()}}, false);
        return () => {
          document.removeEventListener("keydown", (e: KeyboardEvent) => {if(e.key === "Escape") {closeModal()}}, false);
        };
      }, []);

    const dialogWidth = props.width * .75;
    const dialogHeight = ( isPhoneLandscape() )? props.height * .95 : props.height * .8;
    const translate = ( isPhoneLandscape() )? props.height * .28 : props.height * .05;
    const fontSize = (isPhoneLandscape() || dialogHeight < 200 || dialogWidth < 400)? 10 : (dialogHeight < 600 || dialogWidth < 700)? 12 : 16;
    const modalStyles = {
        width: "100%",
        height: dialogHeight,
        maxWidth: "none",
        maxHeight: "none",
        fontSize: fontSize,
        transform: `translateY(-${translate}px)`
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if ((e.relatedTarget instanceof HTMLElement) && e.currentTarget.contains(e.relatedTarget)) {
            // Do not register if click is a child node
            return;
          }
          closeModal();
    }
    return (
        <div id='help-modal' className = 'help-modal' onBlur={handleBlur} tabIndex={-1}>
            <dialog className="dialog" style={modalStyles} open={showHelp}>
                <button onClick={() => closeModal()} className="dialog-close-btn">X</button>
                <div className='help-content'> <h1>Help.</h1>
                    <p>Break all the barriers and be left with exactly 0 power remaining in your laser.</p>
                    <p>The current strength of your laser is displayed at the center of the barriers.</p>
                    <p>Each barrier has:</p>
                        <div><ul>
                            <li>Strength, indicated by the number in the circle behind it.</li>
                            <li>A condiditon to meet. Indicated by the color.</li>
                        </ul></div>
                    <p>
                        Attack barriers by clicking on them. In order for the attempt to be successful, the barrier condition must be met.
                        These conditions are: 
                    </p>
                        <div><ul>
                            <li>Red: Your laser strength must be divisible by 2.</li>
                            <li>Yellow: Your laser strength must be divisible by 3.</li>
                            <li>Blue: Your laser strength must be divisible by 5.</li>
                        </ul></div>

                    <p>If this condition is met, the barrier strength is subtracted from your laser strength. The laser stregth must be greater than or equal to to barrier strength to break the barrier.</p>
                </div>
            </dialog>
        </div>
    )
}
// Retrieve a random puzzle
exports.getRandomPuzzle = () => {
    // Generate random puzzle
    let promise = new Promise(function(resolve) {
        const seed = generateRandomSeed();
        resolve(handleRandomPuzzle(seed));
    });
    return promise;
}

//Generate a random seed
exports.generateRandomSeed =  () => {
    // Generate random puzzle
    return generateRandomSeed();
}

//Use a seed to load a puzzle
exports.loadPuzzleBySeed =  (seed) => {
    // Generate random puzzle
    return handleRandomPuzzle(seed);
}

// Logic to generate random data:
const handleRandomPuzzle = (seed) => {
    //Generate a starting value that cannot open any lock (possibly change to allowing more options later)
    //const validStartingNumbers = [1, 7, 11, 13, 17, 19];
    //const starting = validStartingNumbers[randomInt(0, validStartingNumbers.length-1)];
    let starting = 0;
    let rng = new RNG(seed);

    //Generate the option buttons
    // There will be a random number from 3 to 5 (including the first option below)
    const numberOfOptions = rng.randomInt(2, 4);

    //The first option is always a subtraction. This garuntees that at least one subtraction exists to get to 0
    const firstOption = rng.randomInt(1,19);
    let options = [firstOption];
    let optionsForCalc = [{
        value: firstOption,
        mod: 1,
        operation: '-',
        identifier: 'interaction',
        index: 0
    }];
    for (let i = 0; i < numberOfOptions; i++) {
        const randNum = rng.randomInt(1, 9);
        options.push(randNum);
        optionsForCalc.push({
            value: randNum,
            mod: 1,
            operation: rng.randomOperator(),
            identifier: 'interaction',
            index: i
        });
    }

    //Generate the barriers
    const numberOfBarriers = rng.randomInt(2, 3);
    let barriers = [];
    let barrierTypes = ['fire', 'water', 'electric'];
    for (let i = 0; i < numberOfBarriers; i++) {
        const newBarrierType = rng.shuffleArray(barrierTypes).shift();
        let newBarrier = {
            fire: 0,
            water: 0,
            electric: 0
        }
        const strength = rng.randomInt(1, 9);
        switch(newBarrierType) {
            case 'fire':
                newBarrier.fire = strength;
                optionsForCalc.push({
                    value: strength,
                    mod: 2,
                    operation: '-',
                    identifier: 'barrier',
                    index: i
                });
                break;
            case 'electric':
                newBarrier.electric = strength;
                optionsForCalc.push({
                    value: strength,
                    mod: 3,
                    operation: '-',
                    identifier: 'barrier',
                    index: i
                });
                break;
            case 'water':
                newBarrier.water = strength;
                optionsForCalc.push({
                    value: strength,
                    mod: 5,
                    operation: '-',
                    identifier: 'barrier',
                    index: i
                });
                break;
        }
        barriers.push(newBarrier);
    }

    const randomOrder = rng.shuffleArray(optionsForCalc);

    //Get the first randomized applicable subtraction
    let spliceFound = false;
    let firstOperationData = randomOrder[0];
    for (let i = 0; i < randomOrder.length; i++) {
        const thisPuzzleData = randomOrder[i];
        if((!spliceFound) && (thisPuzzleData.operation == '-') && (thisPuzzleData.value % thisPuzzleData.mod == 0)) {
            spliceFound = true;
            firstOperationData = thisPuzzleData;
            randomOrder.splice(i, 1);
        }
    }
    //Add the first operation to undo subtraction
    starting = starting + firstOperationData.value;

    for (let i = 0; i < randomOrder.length; i++) {
        const thisPuzzleData = randomOrder[i];
        let newStarting = reverseCalculation(starting, thisPuzzleData.operation, thisPuzzleData.value, rng);
        const modCalc = newStarting % thisPuzzleData.mod;
        if(modCalc != 0 && thisPuzzleData.operation == '-' && thisPuzzleData.identifier == 'barrier') {
            // If its a barrier, but the barrier requirement is not met, slightly modify the strength to fit
            const change = thisPuzzleData.mod - modCalc;
            newStarting = newStarting + change;
            if(barriers[thisPuzzleData.index].fire) {
                barriers[thisPuzzleData.index].fire = barriers[thisPuzzleData.index].fire + change
            } else if(barriers[thisPuzzleData.index].electric) {
                barriers[thisPuzzleData.index].electric = barriers[thisPuzzleData.index].electric + change
            } else if(barriers[thisPuzzleData.index].water) {
                barriers[thisPuzzleData.index].water = barriers[thisPuzzleData.index].water + change
            }
        }
        starting = newStarting;
    }


    const randomPuzzle = {
        id: -1,
        title: 'Random Puzzle',
        locks: barriers,
        starting: starting,
        options: options
    }
    return randomPuzzle;
}

function reverseCalculation(starting, operation, change, rng) {
    // If the calculation is impossible, then that button is irrelevant to the solution
    switch(operation) {
        case '-':
            return starting + change;
        case '+':
            if( starting - change > 0 ) {
                return starting - change;
            }
        case 'x':
            if(starting % change == 0) {
                return starting / change;
            }
        case '/':
            // Limit how large the multiplication can get
            // If the multiplication causes the number to be over 100, instead do a random addition or subtraction
            if(starting * change > 100) {
                return reverseCalculation(starting, rng.randomOperator('-', '+'), change, rng);
            }
            return starting * change;
    }
    return starting;
}

//Random generation with Seed
function generateRandomSeed() {
    const RNGm = 0x80000000; // 2**31;
    return Math.floor(Math.random() * (RNGm - 1));
}
function RNG(seed) {
    // LCG using GCC's constants
    this.m = 0x80000000; // 2**31;
    this.a = 1103515245;
    this.c = 12345;

    this.state = seed ? seed : generateRandomSeed()
}
RNG.prototype.nextInt = function() {
    this.state = (this.a * this.state + this.c) % this.m;
    return this.state;
}
RNG.prototype.nextFloat = function() {
    // returns in range [0,1]
    return this.nextInt() / (this.m - 1);
}

RNG.prototype.randomInt = function(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(this.nextFloat() * (max - min + 1)) + min;
}
RNG.prototype.randomOperator = function() {
    const validOperations = ['+', '-', 'x', '/'];
    return validOperations[this.randomInt(0, validOperations.length - 1)];
}
RNG.prototype.shuffleArray = function(array) {
    let thisarray = [...array];
    return thisarray.sort(() => .5 - this.nextFloat());
}
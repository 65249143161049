// Import deps
import React, { useRef, useEffect, useState } from 'react'
import { createPortal } from 'react-dom';
import { useViewingWindow, getScreenClass } from './../../shared/viewing-window'
import { Help } from './help'
import { UserModal } from './user-modal'
import { Results } from './results-modal'

import './../../styles/navbar.css'

//Interfaces
interface StepData {
    startingValue: number;
    endingValue: number;
    interaction: string;
    changeValue: number;
  }

type puzzleStatusUI =  'win' | 'partial' | 'loss' | 'incomplete';

interface NavBarUI {
    currentDisplay: string;
    userUniqueIdentifier: string;
    steps: StepData[];
    bestScore: number | undefined;
    puzzleTitle: string;
    puzzleFinished: puzzleStatusUI;
    setCurrentUserData: (id: number, unique_key: string) => void;
    handleShowMainMenu: () => void;
}

// Create Puzzle component
export const NavBar = (props: NavBarUI) => {
    const [showHelp, setShowHelp] = useState(false);
    const [showUser, setShowUser] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        if(props.puzzleFinished == 'win') {
            setTimeout(function (){
                openResultsModal();
              }, 500);
        }
      }, [props.puzzleFinished]);

    //Detect clicks

    const {width, height} = useViewingWindow();

    const closeAllModals = () => {
        setShowUser(false);
        setShowHelp(false);
        setShowResults(false);
    }

    const openHelpModal = () => {
        // Close the other modals if its on
        closeAllModals();
        setShowHelp(true);
    }
    const closeHelpModal = () => {
        setShowHelp(false);
    }

    const openUserModal = () => {
        // Close the other modals if its on
        closeAllModals();
        setShowUser(true);
    }
    const closeUserModal = () => {
        setShowUser(false);
    }

    const openResultsModal = () => {
        // Close the other modals if its on
        closeAllModals();
        setShowResults(true);
    }
    const closeStepModal = () => {
        setShowResults(false);
    }
    const screenClass=getScreenClass();

    const openMainMenu = () => {
        closeAllModals();
        props.handleShowMainMenu();
    }

    const closeDropdown = () => {
        setShowDropdown(false);
    }

    const handleDropdownClick = (type: string) => {
        closeDropdown();
        if(type == 'main-menu') {
            openMainMenu();
        } else if(type == 'help') {
            openHelpModal();
        } else if(type == 'steps') {
            openResultsModal();
        }
        else if(type == 'user') {
            openUserModal();
        }
    }

    const openDropdown = () => {
        setShowDropdown(true);
    }

    const toggleDropDown = () => {
        if(showDropdown) {
            closeDropdown();
        } else {
            openDropdown();
        }
    }

    const handleDropdownBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if ((e.relatedTarget instanceof HTMLElement) && e.currentTarget.contains(e.relatedTarget)) {
            // Do not register if click is a child node
            return;
          }
        closeDropdown();
    }
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if ((e.relatedTarget instanceof HTMLElement) && e.currentTarget.contains(e.relatedTarget)) {
            // Do not register if click is a child node
            return;
        }
        closeAllModals();
    }

    const contentStyles = {
        display: (showDropdown)? 'block' : 'none'
    }
    return (
        <div className={screenClass}>
            <div className = 'navbar'>
                <div className="dropdown" onBlur={handleDropdownBlur}>
                    <button className="dropbtn" onClick={() => toggleDropDown()}>
                        <svg width="25" height="25" className = 'dropdown-icon'>
                            <line strokeLinecap="butt" stroke="black" strokeWidth="2" y2="6.25" x2="22.5" y1="6.25" x1="2.5"></line>
                            <line strokeLinecap="butt" stroke="black" strokeWidth="2" y2="12.5" x2="22.5" y1="12.5" x1="2.5"></line>
                            <line strokeLinecap="butt" stroke="black" strokeWidth="2" y2="18.25" x2="22.5" y1="18.25" x1="2.5"></line>
                        </svg>
                    </button>
                    <div className="dropdown-content" id = "navbar-dropdown-content" style={contentStyles}>
                        {(props.currentDisplay == 'puzzle-view') ? <a href="#" onClick={() => handleDropdownClick('main-menu')}>Main Menu</a> : ''}
                        {(props.currentDisplay == 'puzzle-view') ? <a href="#" onClick={() => handleDropdownClick('help')}>Help</a> : ''}
                        {(props.currentDisplay == 'puzzle-view') ? <a href="#" onClick={() => handleDropdownClick('steps')}>Show Steps</a> : ''}
                        <a href="#" onClick={() => handleDropdownClick('user')}>User</a>
                    </div>
                </div>
                {showHelp &&  createPortal( <Help closeModal={closeHelpModal} width={width} height={height} />, document.getElementById('help-modal-portal')!) }
                {showUser &&  createPortal( <UserModal closeModal={closeUserModal} width={width} height={height} userUniqueIdentifier={props.userUniqueIdentifier} setCurrentUserData={props.setCurrentUserData} />, document.getElementById('user-modal-portal')!) }
                {showResults && createPortal( <Results closeModal={closeStepModal} width={width} height={height} steps={props.steps} bestScore={props.bestScore} puzzleTitle={props.puzzleTitle} puzzleFinished={props.puzzleFinished}/>, document.getElementById('results-modal-portal')!) }
            </div>
            <div id="help-modal-portal"></div>
            <div id="user-modal-portal"></div>
            <div id="results-modal-portal"></div>
        </div>
    )
}
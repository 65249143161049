// Import deps
import React from 'react'

// Import components
import { PuzzleRow } from './puzzle-row'
import { isPhoneLandscape } from './../../shared/viewing-window'

// Import styles
import './../../styles/puzzle-list.css'

// Create interfaces
interface PuzzleUI {
  title: string;
  score: number | undefined;
}

interface PuzzleListUI {
  puzzles: PuzzleUI[];
  user: number;
  handlePickPuzzle: (puzzleTitle: string) => void;
}

// Create PuzzleList component
export const PuzzleList = (props: PuzzleListUI) => {
  // Show loading message
  if( isPhoneLandscape() ) return <p>Please use portrait mode to display list of past puzzles.</p>

  return (
    <div className='puzzle-list'>
    <table className="table">
        <thead>
          <tr>
            <th className="table-head-item" />

            <th className="table-head-item">Scores from the last 7 days.</th>

            <th className="table-head-item" />
          </tr>
        </thead>
        <tbody className="table-body">
          {props.puzzles.length > 0 ? (
            props.puzzles.map((puzzle: PuzzleUI, idx) => (
              <PuzzleRow
                key={puzzle.title}
                puzzle={puzzle}
                user={props.user}
                position={idx + 1}
                handlePickPuzzle={props.handlePickPuzzle}
              />
              )
            )
          ) : <tr><td></td><td></td><td></td></tr>
        }
        </tbody>
    </table>
    </div>
  )
}
import React, { useEffect, useState } from 'react'
import { useViewingWindow } from './../../shared/viewing-window'

interface BarrierUI {
    type: string;
    strength: number;
    lockRadius: number;
    totalLocks: number;
    currentLock: number;
    scoreRadius: number;
    translateWidth: number;
    translateHeight: number;
    handleAttack: (onClickEvent: any) => void;
}

// Create Puzzle component
export const BarrierPart = (props: BarrierUI) => {
    let classModifier = '';
    if(props.type == "fire") {
        classModifier = " red";
    }
    if(props.type == "electric") {
        classModifier = " yellow";
    }
    if(props.type == "water") {
        classModifier = " blue";
    }
    const laserClass = "laser-barrier" + classModifier;

    function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
        var angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;
      
        return {
          x: centerX + (radius * Math.cos(angleInRadians)),
          y: centerY + (radius * Math.sin(angleInRadians))
        };
    }
      
    function describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number){
      
        var start = polarToCartesian(x, y, radius, endAngle);
        var end = polarToCartesian(x, y, radius, startAngle);

        var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

        var d = [
            "M", start.x, start.y, 
            "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
        ].join(" ");

        return d;       
    };

    //Describe Arc:
    //Center X coordinate
    //Center Y coordinate
    //Radius
    //Start Of Arc with top of the circle being 0 (in degrees)
    //End of the Arc with top of the circle being 0 (in degrees)

    const startingAngle = (props.currentLock - 1) * 360 / props.totalLocks;
    const endingAngle = (props.currentLock) * 360 / props.totalLocks;
    const barrierRadius = props.lockRadius + props.scoreRadius;

    const translateWidthStart = props.translateWidth - barrierRadius * (2/2); 
    const translateHeightStart = props.translateHeight - barrierRadius * (2/2) + props.scoreRadius;
    const styles = { 
       transform: `translate(${translateWidthStart}px, ${translateHeightStart}px)`,
    };

    // Strength view
    const circleClass = "circle" + classModifier;
    const middleOfBarrier = (startingAngle + endingAngle) / 2;
    const strengthTranslateWidth = (props.translateWidth - props.scoreRadius) + (barrierRadius + 25) * Math.sin(middleOfBarrier * Math.PI/180);
    const strengthTranslateHeight = props.translateHeight - (barrierRadius * 1.1) * Math.cos(middleOfBarrier * Math.PI/180);
    const stregthStyle = {
        transform: `translate(${strengthTranslateWidth}px, ${strengthTranslateHeight}px)`,
    }
    
    return (
        <>
        <div className='barrier-holder' onClick={(e) => props.handleAttack(e)}>
            <div className='barrier-part-holder' style={styles}>
                <svg 
                    className={laserClass}
                    strokeWidth="20" 
                    fill="transparent" 
                    width={barrierRadius * 2} 
                    height={barrierRadius * 2}
                    viewBox="0 0 300 300"
                >
                    <path d={describeArc(150,150,100, startingAngle, endingAngle)} />
                </svg>
            </div>
        </div>
        <div className='barrier-strength' style={stregthStyle}>
            <div className={circleClass}>{props.strength}</div>
        </div>
        </>
    )
}